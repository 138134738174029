import { useTranslation } from "react-i18next";
import "./index.css";
const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
    style={{
      display: window.location.href.includes('admin') ? 'none':"block",
    }}
    >
      <p>{t("footer")}</p>
    </footer>
  );
};

export default Footer;
